import { env } from "$env/dynamic/public";
import { init } from '@jill64/sentry-sveltekit-cloudflare/client'

const onError = init(
    env.PUBLIC_SENTRY_DSN
)

export const handleError = onError((_, sentryEventId) => ({
    message: 'This error was successfully sent to Sentry',
    sentryEventId
}))